import React from 'react';
import { graphql } from 'gatsby';
import { TeamCard } from '../components/cards/team-card';
import { Layout } from '../components/layout/layout';
import { PageHeading } from '../components/layout/page-heading';
import { PageSubHeading } from '../components/layout/page-subheading';

const SingleTeam = ({ teamName, team, teamOrder, isLast = false }) => {
  return (
    <>
      <PageSubHeading title={teamName} styles={'mt-8'} />
      <div className="mt-6 grid md:grid-cols-6 gap-4">
        {team.map((teamMember) => (
          <TeamCard
            key={teamMember.id}
            teamMember={teamMember}
            teamOrder={teamOrder}
          />
        ))}
      </div>
      {!isLast && <hr className="mt-8" />}
    </>
  );
};

const Team = ({ data }) => {
  const { team: teams, title } = data.contentfulTeamPage;

  return (
    <Layout title={'Team'}>
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-0">
        <div className="max-w-2xl mx-auto py-8 lg:max-w-none">
          <PageHeading title={title} />
          {teams.map((team, i) => (
            <SingleTeam
              key={team.id}
              teamName={team.teamName}
              team={team.teamMembers}
              teamOrder={i}
              isLast={i === teams.length}
            />
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Team;

export const query = graphql`
  query {
    contentfulTeamPage {
      title
      team {
        id
        teamName
        teamMembers {
          id
          name
          role
          portrait {
            fixed(width: 400, height: 400) {
              width
              height
              src
              srcSet
            }
          }
        }
      }
    }
  }
`;
