import React from 'react';

export const TeamCard = ({ teamMember, teamOrder }) => {
  let bgColor = 'bg-primaryLight';
  switch (teamOrder) {
    case 0:
      bgColor = 'bg-primaryLight';
      break;
    case 1:
      bgColor = 'bg-blueVeryLight';
      break;
    case 2:
      bgColor = 'bg-greenLight';
      break;
    case 3:
      bgColor = 'bg-purpleLight';
      break;
    case 4:
      bgColor = 'bg-pinkLight';
      break;
    case 5:
      bgColor = 'bg-yellowLight';
      break;
  }

  return (
    <div className={`flex flex-col h-full ${bgColor} rounded-lg shadow-md`}>
      <div className="self-center overflow-hidden">
        <img
          className="w-full h-full object-center rounded-t-lg object-contain"
          alt={`${teamMember.name}`}
          src={teamMember.portrait.fixed.src}
        />
      </div>
      <div className="p-4">
        <h2 className="text-gray-800 font-heading text-lg font-semibold">
          {teamMember.name}
        </h2>
        <p className="text-gray-500 text-md font-semibold mt-2">
          {teamMember.role}
        </p>
      </div>
    </div>
  );
};
